<template>
  <div class="pageContol listWrap templateList formCom courseType1">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">课程分类</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="分类名称" class="searchboxItem ci-full">
              <span class="itemLabel">分类名称:</span>
              <el-input
                size="small"
                placeholder="请输入分类名称"
                v-model="typeName"
                clearable
              >
              </el-input>
            </div>
            <el-button
              style="margin-left: 20px"
              class="bgc-bv"
              round
              @click="getData()"
              >查询</el-button
            >
          </div>
          <div class="searchbox"></div>
          <div class="btnBox">
            <el-button class="bgc-bv" @click="courseTypeAddFirst"
              >新增课程分类</el-button
            >
          </div>
        </div>
        <div class="summary-title">
            <div class="summary-title-item">客户端显示：{{countClient}}</div>
            <div class="summary-title-item">管理后台显示：{{countPc}}</div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              v-if="refreshTable"
              :data="courseTypeTree"
              :header-cell-style="tableHeader"
              height="100%"
              size="small"
              style="width: 100%"
              row-key="courseTypeId"
              @cell-mouse-enter="mouseenter"
              @cell-mouse-leave="mouseleave"
              :default-expand-all="defaultTree"
            >
              <el-table-column
                prop="name"
                label="分类名称"
                align="left"
              ></el-table-column>
              <el-table-column
                prop="courseNum"
                label="课程数量"
                align="center"
              ></el-table-column>
              <el-table-column label="客户端" align="center">
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.showState"
                    active-color="#ff4949"
                    inactive-color="#c0ccda"
                    @change="courseTypeChangehidden(scope.row)"
                  >
                  </el-switch>
                  <span style="margin-left: 5px">{{
                    !scope.row.showState ? "隐藏" : "显示"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="管理后台" align="center">
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.showStatePc"
                    active-color="#ff4949"
                    inactive-color="#c0ccda"
                    @change="courseTypeChangeHiddenPc(scope.row, scope.$index)"
                  >
                  </el-switch>
                  <span style="margin-left: 5px">{{
                    !scope.row.showStatePc ? "隐藏" : "显示"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="工伤预防" align="center">
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.showStateGsyf"
                    active-color="#ff4949"
                    inactive-color="#c0ccda"
                    @change="courseTypeChangeHiddenGsyf(scope.row, scope.$index)"
                  >
                  </el-switch>
                  <span style="margin-left: 5px">{{
                    !scope.row.showStateGsyf ? "隐藏" : "显示"
                  }}</span>
                </template>
              </el-table-column>
              
              <el-table-column prop="order" label="排序" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.order }}</span>
                  <i
                    class="el-icon-edit"
                    style="margin-left: 10px"
                    v-if="mouseenterId == scope.row.courseTypeId"
                    @click="showOrderInput"
                  >
                  </i>
                  <el-input
                    type="number"
                    size="mini"
                    v-model="courseTypeOrderValue"
                    v-if="mouseenterIdclicked == scope.row.courseTypeId"
                    placeholder="请输入排序"
                    @change="changeOrder(scope.row)"
                    maxlength="3"
                    show-word-limit
                    oninput="value=value.replace(/[^\d.]/g,'')"
                  >
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column prop="address" label="操作" align="center" width="280px">
                <div slot-scope="scope" class="">
                  <el-button
                    size="mini"
                    type="text"
                    @click="courseTypeEditDialog(scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    @click="courseTypeDel(scope.row)"
                    >删除</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    @click="courseTypeAddChild(scope.row)"
                    v-if="scope.row.parentId == '0'"
                    >新增子分类</el-button
                  >
                </div>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="courseTypeDialogTitle"
      :visible.sync="courseTypeDialogVisible"
      width="30%"
      center
    >
      <el-form
        ref="courseTypeDialogFromRef"
        :model="courseTypeDialogFrom"
        :rules="courseTypeDialogFromRules"
        label-width="120px"
      >
        <el-form-item label="一级分类名称" prop="firstName" size>
          <el-input
            v-model="courseTypeDialogFrom.firstName"
            maxlength="15"
            show-word-limit
            :disabled="courseTypeDialogFrom.firstNameDisabled"
          ></el-input>
        </el-form-item>
        <el-form-item label="二级分类名称" prop="secondName">
          <el-input
            v-model="courseTypeDialogFrom.secondName"
            maxlength="15"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item
          label="二级分类排序"
          prop="secondOrder"
          v-if="courseTypeDialogFrom.firstNameDisabled"
        >
          <el-input
            v-model.number="courseTypeDialogFrom.secondOrder"
            maxlength="3"
            show-word-limit
            oninput="value=value.replace(/[^\d.]/g,'')"
          ></el-input>
          <span style="font-size: 5px"
            >数字范围为0~255，数字越小越靠前。如果数字相同，先添加的排序靠前</span
          >
        </el-form-item>
        <el-form-item
          label="一级分类排序"
          prop="firstOrder"
          v-if="!courseTypeDialogFrom.firstNameDisabled"
        >
          <el-input
            v-model.number="courseTypeDialogFrom.firstOrder"
            maxlength="3"
            show-word-limit
            oninput="value=value.replace(/[^\d.]/g,'')"
          ></el-input>
          <span style="font-size: 5px"
            >数字范围为0~255，数字越小越靠前。如果数字相同，先添加的排序靠前</span
          >
        </el-form-item>
        <el-form-item label="客户端" prop="showState">
          <el-switch v-model="courseTypeDialogFrom.showState"> </el-switch>
          <span style="margin-left: 5px">{{
            courseTypeDialogFrom.showState ? "显示" : "隐藏"
          }}</span>
        </el-form-item>
        <el-form-item label="管理后台" prop="showStatePc">
          <el-switch v-model="courseTypeDialogFrom.showStatePc"> </el-switch>
          <span style="margin-left: 5px">{{
            courseTypeDialogFrom.showStatePc ? "显示" : "隐藏"
          }}</span>
        </el-form-item>
        <el-form-item label="工伤预防" prop="showStateGsyf">
          <el-switch v-model="courseTypeDialogFrom.showStateGsyf">
          </el-switch>
          <span style="margin-left: 5px">{{
            courseTypeDialogFrom.showStateGsyf ? "显示" : "隐藏"
          }}</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="courseTypeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="courseTypeAdd()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="'修改分类'"
      :visible.sync="courseTypeEditDialogVisible"
      width="30%"
      center
    >
      <el-form
        ref="courseTypeEditDialogFromRef"
        :model="courseTypeEditDialogFrom"
        :rules="courseTypeEditDialogFromRules"
        label-width="120px"
      >
        <el-form-item label="分类名称" prop="name">
          <el-input
            v-model="courseTypeEditDialogFrom.name"
            maxlength="15"
            show-word-limit
          ></el-input>
        </el-form-item>

        <el-form-item label="排序" prop="order">
          <el-input
            type="number"
            v-model.number="courseTypeEditDialogFrom.order"
            maxlength="3"
            show-word-limit
            oninput="value=value.replace(/[^\d.]/g,'')"
          ></el-input
          ><br />
          <span style="font-size: 5px"
            >数字范围为0~255，数字越小越靠前。如果数字相同，先添加的排序靠前</span
          >
        </el-form-item>
        <el-form-item label="客户端" prop="showState">
          <el-switch v-model="courseTypeEditDialogFrom.showState"> </el-switch>
          <span style="margin-left: 5px">{{
            courseTypeEditDialogFrom.showState ? "显示" : "隐藏"
          }}</span>
        </el-form-item>
        <el-form-item label="管理后台" prop="showStatePc">
          <el-switch v-model="courseTypeEditDialogFrom.showStatePc">
          </el-switch>
          <span style="margin-left: 5px">{{
            courseTypeEditDialogFrom.showStatePc ? "显示" : "隐藏"
          }}</span>
        </el-form-item>
        <el-form-item label="工伤预防" prop="showStateGsyf">
          <el-switch v-model="courseTypeEditDialogFrom.showStateGsyf">
          </el-switch>
          <span style="margin-left: 5px">{{
            courseTypeEditDialogFrom.showStateGsyf ? "显示" : "隐藏"
          }}</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="courseTypeEditDialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="courseTypeEdit()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import List from "@/mixins/List";
export default {
  name: "mini/courseType",
  mixins: [List],
  data() {
    return {
      mouseenterId: 0,
      mouseenterIdclicked: 0,
      courseTypeOrderValue: "",
      courseTypeDialogVisible: false,
      courseTypeDialogTitle: "新增课程分类",
      courseTypeEditDialogVisible: false,
      courseTypeDialogFrom: {
        firstName: "", //一级分类名称
        secondName: "", //二级分类名称
        parentId: "", //父级ID
        firstNameDisabled: "", //一级分类是否可编辑
        secondOrder: 0, //二级分类排序
        firstOrder: 0, //二级分类排序
        showState: "", //客户端是否隐藏
        showStatePc: "", // 管理后台显示隐藏
        showStateGsyf:"", // 工伤预防显示隐藏
      },
      courseTypeDialogFromRules: {
        firstName: [
          {
            required: true,
            message: "请输入一级分类名称",
            trigger: "blur",
          },
        ],
        secondName: [
          {
            required: true,
            message: "请输入二级分类名称",
            trigger: "blur",
          },
        ],
        secondOrder: [
          {
            required: true,
            message: "请输入二级分类排序",
            trigger: "blur",
          },
          { type: "number", message: "分类排序必须为数字值" },
          { validator: this.validatePass, trigger: "blur" },
        ],
        firstOrder: [
          {
            required: true,
            message: "请输入一级分类排序",
            trigger: "blur",
          },
          { type: "number", message: "分类排序必须为数字值" },
          { validator: this.validatePass, trigger: "blur" },
        ],
      },
      courseTypeEditDialogFrom: {
        name: "", //名称
        courseTypeId: "", //分类id
        parentId: "", //父级id
        order: 0, //排序
        showState: "", //客户端是否隐藏
        showStatePc: "", // 管理后台显示隐藏
        showStateGsyf:"", // 工伤预防显示隐藏
      },
      courseTypeEditDialogFromRules: {
        name: [
          {
            required: true,
            message: "请输入分类名称",
            trigger: "blur",
          },
        ],
        showState: [
          {
            required: true,
            message: "请选择是否隐藏",
            trigger: "blur",
          },
        ],
        order: [
          {
            required: true,
            message: "请输入分类排序",
            trigger: "blur",
          },
          { type: "number", message: "分类排序必须为数字值" },
          { validator: this.validatePass, trigger: "blur" },
        ],
        courseTypeId: [
          { required: true, message: "请选择分类", trigger: "blur" },
        ],
      },
      courseTypeTree: [],
      typeName: "", //分类名称
      defaultTree: false,
      refreshTable: false, // 渲染dom表格
      // 客户端汇总显示
      countClient:0,
      // 管理后台汇总显示 
      countPc:0
    };
  },

  filters: {
    hidden(value) {
      console.log(value);
      if (!value) {
        return "显示";
      }
      return "隐藏";
    },
  },
  created() {
    this.getData();
  },
  computed: {},
  methods: {
    validatePass(rule, value, callback) {
      if (value > 255) {
        callback(new Error("最大值应在255以内"));
      } else {
        callback();
      }
    },
    // 获取汇总信息
    async summary(){
        try{
            const {typeName}=this;
            const result=await this.$post("/miniapp/coursetype/selectTreeCount",{typeName},3000,true,2);
            this.countPc=result.data.countPc;
            this.countClient=result.data.countClient;
        }catch(e){
            return;
        }
    },
    //获取分类
    getData() {
      let params = {
        typeName: this.typeName,
      };

      this.$post("/miniapp/coursetype/selectTree", params, 3000, true, 2)
        .then((res) => {
          if (res.status == "0") {
            this.refreshTable = false;
            this.courseTypeTree = res.data;
            // console.log(res.data);
            // 统计客户端显示数
            // this.clientNum = this.summary(res.data,"showState");
            // 统计管理后台显示数
            // this.managementNum = this.summary(res.data,"showStatePc");
            this.$nextTick(() => {
              if (this.typeName) {
                this.defaultTree = true;
              } else {
                this.defaultTree = false;
              }
              this.refreshTable = true;
            });
          }
        })
        .catch(() => {
          return;
        });
        this.summary();
    },
    //修改分类显示状态
    courseTypeChangehidden(info) {
      const params = {
        courseTypeId: info.courseTypeId,
        showState: info.showState,
      };
      this.$post("/miniapp/coursetype/changeHidden", params, 3000, true, 2)
        .then((res) => {
          this.$message({
            type: res.status == 0 ? "success" : "error",
            message: res.message ? res.message : "操作失败",
          });
          this.getData();
        })
        .catch(() => {
          this.getData();
          this.courseTypeTree[index].showState = false;
        });
    },
    //修改分类显示状态PC端
    courseTypeChangeHiddenPc(info, index) {
      const params = {
        courseTypeId: info.courseTypeId,
        showStatePc: info.showStatePc,
      };
      this.$post("/miniapp/coursetype/changeHidden", params, 3000, true, 2)
        .then((res) => {
          this.$message({
            type: res.status == 0 ? "success" : "error",
            message: res.message ? res.message : "操作失败",
          });
          this.getData();
        })
        .catch(() => {
          this.getData();
          this.courseTypeTree[index].showStatePc = false;
        });
    },
    //修改分类显示状态 工伤预防
    courseTypeChangeHiddenGsyf(info, index) {
      const params = {
        courseTypeId: info.courseTypeId,
        showStateGsyf: info.showStateGsyf,
      };
      this.$post("/miniapp/coursetype/changeHidden", params, 3000, true, 2)
        .then((res) => {
          this.$message({
            type: res.status == 0 ? "success" : "error",
            message: res.message ? res.message : "操作失败",
          });
          this.getData();
        })
        .catch(() => {
          this.getData();
          this.courseTypeTree[index].showStateGsyf = false;
          // return;
        });
    },
    //添加分类接口
    courseTypeAdd() {
      this.$refs["courseTypeDialogFromRef"].validate((valid) => {
        if (valid) {
          const params = [
            {
              name: this.courseTypeDialogFrom.firstName,
              order: this.courseTypeDialogFrom.firstOrder,
              showState: this.courseTypeDialogFrom.showState,
              showStatePc: this.courseTypeDialogFrom.showStatePc,
              appRegisterInfoId: this.$appRegisterInfoId,
              showStateGsyf: this.courseTypeDialogFrom.showStateGsyf,
              children: [
                {
                  name: this.courseTypeDialogFrom.secondName,
                  order: this.courseTypeDialogFrom.secondOrder,
                  showState: this.courseTypeDialogFrom.showState,
                  showStatePc: this.courseTypeDialogFrom.showStatePc,
                  showStateGsyf: this.courseTypeDialogFrom.showStateGsyf,
                },
              ],
            },
          ];
          if (this.courseTypeDialogFrom.parentId) {
            params[0].children[0].parentId = params[0].courseTypeId =
              this.courseTypeDialogFrom.parentId;
          }
          this.$post("/miniapp/coursetype/insert", params, 3000, true, 2)
            .then((res) => {
              this.$message({
                type: res.status == "0" ? "success" : "error",
                message: res.message ? res.message : "操作失败",
              });
              if (res.status == 0) {
                this.$refs["courseTypeDialogFromRef"].resetFields();
                this.courseTypeDialogVisible = false;
                this.getData();
              }
            })
            .catch(() => {
              return;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 添加子分类弹窗
    courseTypeAddChild(row) {
      if (this.$refs["courseTypeDialogFromRef"] !== undefined) {
        this.$refs["courseTypeDialogFromRef"].resetFields();
      }
      this.courseTypeDialogVisible = true;
      this.courseTypeDialogFrom.parentId = row.courseTypeId;
      this.courseTypeDialogFrom.firstName = row.name;
      this.courseTypeDialogFrom.firstOrder = row.order;
      this.courseTypeDialogFrom.showState = row.showState;
      this.courseTypeDialogFrom.showStatePc = row.showStatePc;
      this.courseTypeDialogFrom.showStateGsyf = row.showStateGsyf;
      this.courseTypeDialogFrom.firstNameDisabled = true;
      this.courseTypeDialogTitle = "增加子分类";
    },
    // 增加主分类弹窗
    courseTypeAddFirst() {
      if (this.$refs["courseTypeDialogFromRef"] !== undefined) {
        this.$refs["courseTypeDialogFromRef"].resetFields();
      }
      this.courseTypeDialogVisible = true;
      this.courseTypeDialogFrom.parentId = "";
      this.courseTypeDialogFrom.firstName = "";
      this.courseTypeDialogFrom.firstOrder = 0;
      this.courseTypeDialogFrom.secondName = "";
      this.courseTypeDialogFrom.showState = true;
      this.courseTypeDialogFrom.showStatePc = true;
      this.courseTypeDialogFrom.showStateGsyf = false;
      this.courseTypeDialogFrom.firstNameDisabled = false;
      this.courseTypeDialogTitle = "新增课程分类";
    },
    // 编辑分类弹窗
    courseTypeEditDialog(info) {
      console.log(info)
      if (this.$refs["courseTypeEditDialogFromRef"] !== undefined) {
        this.$refs["courseTypeEditDialogFromRef"].resetFields();
      }
      this.courseTypeEditDialogVisible = true;
      this.courseTypeEditDialogFrom.name = info.name;
      this.courseTypeEditDialogFrom.showState = info.showState;
      this.courseTypeEditDialogFrom.showStatePc = info.showStatePc;
      this.courseTypeEditDialogFrom.showStateGsyf = info.showStateGsyf;
      this.courseTypeEditDialogFrom.order = info.order;
      this.courseTypeEditDialogFrom.courseTypeId = info.courseTypeId;
      this.courseTypeEditDialogFrom.parentId = info.parentId;
    },
    // 编辑分类
    courseTypeEdit() {
      this.$refs["courseTypeEditDialogFromRef"].validate((valid) => {
        if (valid) {
          this.$post(
            "/miniapp/coursetype/modify",
            this.courseTypeEditDialogFrom,
            3000,
            true,
            2
          )
            .then((res) => {
              this.$message({
                type: res.status == "0" ? "success" : "error",
                message: res.message ? res.message : "操作失败",
              });
              if (res.status == 0) {
                this.$refs["courseTypeEditDialogFromRef"].resetFields();
                this.courseTypeEditDialogVisible = false;
                this.getData();
              }
            })
            .catch(() => {
              return;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 删除
    courseTypeDel(info) {
      this.$confirm("删除后，将不可恢复", "确定删除吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post(
            "/miniapp/coursetype/deleteCourseType",
            info,
            3000,
            true,
            2
          ).then((ret) => {
            ret.status === "0"
              ? this.getData()
              : this.$message.error(ret.message || "刪除失败！");
          });
        })
        .catch(() => {
          return;
        });
    },
    //排序单元格 鼠标移入事件
    mouseenter(row, column, cell, event) {
      this.mouseenterId = row.courseTypeId;
    },
    //排序单元格 鼠标移除事件
    mouseleave(row, column, cell, event) {
      this.mouseenterIdclicked = this.mouseenterId = "";
    },
    //排序单元格 编辑图标 点击时间
    showOrderInput() {
      this.mouseenterIdclicked = this.mouseenterId;
    },
    //更改分类排序
    changeOrder(info) {
      const params = {
        courseTypeId: info.courseTypeId,
        order: this.courseTypeOrderValue,
      };
      this.$post("/miniapp/coursetype/changeOrder", params, 3000, true, 2)
        .then((res) => {
          this.$message({
            type: res.status == "0" ? "success" : "error",
            message: res.message ? res.message : "操作失败",
          });
          this.getData();
          this.courseTypeOrderValue = "";
        })
        .catch(() => {
          return;
        });
    },
  },
};
</script>
<style lang="less" scoped>
// .courseType1 {
//     .el-switch.is-checked .el-switch__core {
//         background-color: #c0ccda !important;
//         border-color: #c0ccda !important;
//     }
// }
    .summary-title{
        display: flex;
        padding-left:12px;
        .summary-title-item{
            margin-right: 20px;
        }
    }
</style>
